(function ( $ ) {
  $.fn.tuckit = function(options) {
    // Options
    var settings = $.extend({
      slideSpeed:     300,
      openClass:      'target-untucked',
      openElClass:    'untucked',
      closedElClass:  'tucked',
      closeAllOnOpen: true
    }, options);

    // The Main Event
    this.click(function(event){
      event.preventDefault();
      var $this = $(this);
      var target = $this.attr('data-target');
      // Toggle Open Class
      $this.toggleClass(settings.openClass);

      // Toggle target element and make it slide
      $(target).slideToggle(settings.slideSpeed, function(){
        var $target = $(this);

        if($target.hasClass(settings.openElClass) ) {
          $target.addClass(settings.closedElClass).removeClass(settings.openElClass);
        }
        if( $target.hasClass(settings.closedElClass) ) {
          $target.addClass(settings.openElClass).removeClass(settings.closedElClass);
        }
      });

      // Aria Controls
      if($this.attr('aria-expanded') === 'false') {
        $this.attr('aria-expanded', 'true');
      } else {
        $this.attr('aria-expanded', 'false');
      }
    });

  };

}( jQuery ));