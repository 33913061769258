(function($) {


var FleisherClasses = {
  // All pages
  post_type_archive_classes: {
    init: function() {

      // DEFINE VARS
      // ============================================================

      var activeTermArray    = [];    // Array to store terms that are active
      var visibleTermArray   = [];    // Array to store visible class terms
      var fadeSpeed          = 300;   // Animation Speed

      var hashSep            = '&';
      var hashArray          = [];

      var visibleClass       = 'matching-class';
      var activeTermClass    = 'active-term';
      var filterTagClass     = 'filter-tag';
      var nonActiveTermClass = 'non-active-term';
      var filteringClass     = 'filtering';

      var $classContainer    = $('#class-container');
      var $noClasses         = $('#no-classes-found');



      // DEFINE FUNCTIONS
      // ============================================================


      // Check each filter term link to see if it's data-term-target exists
      // in visible term array.
      function tax_filter(visibleTermArray) {   // (4)
        // VARS
        var $filterLinks = $('.filter-term-link');

        // Each Filter link
        $filterLinks.each(function(){
          var $this  = $(this);
          var term   = $this.attr('data-term-target');
          var $found = $.inArray(term, visibleTermArray);

          // If the term is in the array, the term is visible, remove the non-active class
          if( $found > -1 ) {
            $this.removeClass(nonActiveTermClass);
          // Otherwise, its no longer relateable, so give it the non-active class
          } else {
            $this.addClass(nonActiveTermClass);
          }

        });
      }


      // --------------------------------------------------------------------


      // Get each visible class and load each of its data-class-filter attrs into
      // the visibleTermArray.
      function test_active() {    // (3)
        var $classPost = $('.class-post:visible');
        var classCount = $classPost.length;

        $classPost.each(function(){
          var $this     = $(this);
          var terms     = $this.attr('data-class-filters');
          var termArray = terms.split(',');

          // Foreach value in termArray, add value to visibleTermArray if its not
          // already there.
          $.each(termArray, function(i, value) {

            var $termFound = $.inArray(value, visibleTermArray);

            if($termFound < 0 && value.length > 0) {
              visibleTermArray.push(value);
            }
          });

          // Check each term link to see if it is relational to what is in view
          if (--classCount === 0) {
            tax_filter(visibleTermArray);
          }
        });
      }


      // --------------------------------------------------------------------


      // Show or hide the 'No Classes message'
      function no_class() {     // (2)
        // If there are NO posts that are visible
        if( $('.class-post:visible').length === 0 ) {
          $noClasses.animate({'opacity': 1}, fadeSpeed, function(){
            $noClasses.attr('aria-hidden', 'false');
          });
        // If not,
        } else {
          $noClasses.animate({'opacity': 0}, fadeSpeed, function(){
            $noClasses.attr('aria-hidden', 'true');
          });
        }
      }


      // --------------------------------------------------------------------


      // Iterate over posts to find elements with matching data-class-filter attributes
      function scrub_classes(activeTermArray) {

        var $classPost = $('.class-post');
        var classCount = $classPost.length;

        $classPost.each(function(){
          var $this           = $(this);
          var terms           = $this.attr('data-class-filters');
          var activeTermCount = 0;

          // Run each array value to see if it exists as an attribute
          $.each(activeTermArray, function(i, activeTerm){
            // If the array term exists in the data-class-filter
            if( terms.indexOf(activeTerm) > -1 ) {
              // if it does, increase the count
              activeTermCount++;
            }
          });

          // If the count of terms in data-class-filter equal the amount of items in the
          // array, the post matches all of the criteria, so show it. Otherwise, hide it.

          if(activeTermCount === activeTermArray.length) {
            $this.fadeIn(fadeSpeed, function(){
              $this.addClass(visibleClass);
            });

          } else {
            $this.fadeOut(fadeSpeed, function(){
              $this.removeClass(visibleClass);
            });

          }

          // Once each class-post has been processed
          if (--classCount === 0) {
            // When all animations are complete
            $(":animated").promise().done(function() {
              // Test to see if any classes are visible
              no_class();
            });

            setTimeout(function(){
              $classContainer.removeClass(filteringClass);
              test_active();
            }, 500);
          }
        });
      }


      // --------------------------------------------------------------------


      // Add Filter Bar Link
      function add_filter_bar_button(link) {
        var filterTagBar      = $('#filter-term-bar');
        var filterButtonClass = 'button-' + link.attr('data-term-target');
        var filterButtonText  = link.text();
        var filterTerms       = link.attr('data-term-target');
        var linkID            = link.attr('id');


        // Add button only if it does not already exist
        if( filterTagBar.find( '.' + filterButtonClass ).length === 0 ) {
          var filterHTML =  '<a href="#" class="'+filterTagClass+' '+filterButtonClass+'" data-tag-target="'+filterTerms+'" data-link-id="'+linkID+'">' +
                              filterButtonText + ' <i class="fa fa-times"></i>' +
                            '</a>';
          filterTagBar.parent().parent().addClass('active-tags');
          filterTagBar.append(filterHTML);
          $('#clear-all').fadeIn(fadeSpeed);
        }

        if(filterTagBar.children().length === 0) {
          window.location.href.substr(0, window.location.href.indexOf('#'));
        }
      }


      // --------------------------------------------------------------------


      // Update the Location Hash
      function update_location_hash(activeTermArray) {
        var hashList = '#filters=';
        var arrayCount = activeTermArray.length;

        // Add Each Term to hash
        $.each(activeTermArray, function(i, activeTerm){

          if( i > 0 ) { // The first term needs no &
            hashList += '&';
          }
          hashList += activeTerm.replace(/-/g, '\+');

        });

        window.location.hash = hashList;
      }


      // --------------------------------------------------------------------







      // EVENTS
      // ============================================================

      // FILTER IF URL ALREADY HAS TERMS
      // -------------------------------

      $(window).load(function () {
        var hash = window.location.hash;
        var filterBarInit = $('#filter-bar-text-init');

        // Only run if filters are in the url
        if(hash.indexOf('#filters=') === 0 && hash.length > 9) {

          // Make attrs match standard attrs
          hashArray = hash.replace("#filters=", "").replace(/\+/g, '-').split('&');

          // Enable the filter bar
          filterBarInit.parent().parent().removeClass('filter-init');
          filterBarInit.hide();

          var hashLength = hashArray.length;

          // Scurb Posts using the terms from the hash
          scrub_classes(hashArray);

          // Update links and filter bar
          $.each(hashArray, function(i, term){
            $('#filter-terms').find('[data-term-target='+term+']').each(function(){
              var $this = $(this);

              // Add active class
              $this.addClass(activeTermClass);

              // Add button to filter bar
              add_filter_bar_button($this);
            });
          });
        }

        // Fade in the container
        $classContainer.removeClass(filteringClass);
      });
      // -------------------------------



      // FADE OUT 'EXPLORE LISTINGS'
      // ---------------------------

      $('#filter-bar-text-init').click(function(event){
        event.preventDefault();
        var $this = $(this);

        $this.fadeOut(100, function(){
          var toggle = $('.term-list-toggle');
          var toggleTarget = toggle.attr('data-target');

          $this.parent().parent().removeClass('filter-init');
          toggle.addClass('target-untucked').attr('aria-expanded', 'true');

          $(toggleTarget).slideToggle(300, function(){
            $this.addClass('untucked').removeClass('tucked');
          });

          $this.hide();
        });
      });
      // ---------------------------



      // FILTER LINKS
      // ------------
      // Clicking a link in the Filter List

      $('.filter-term-link').click(function(event){
        event.preventDefault();

        visibleTermArray = []; // Clears array so it can be rebuilt

        // Vars
        var $thisLink    = $(this);
        var termTarget   = $thisLink.attr('data-term-target');
        var $termFound   = $.inArray(termTarget, activeTermArray);

        // Escape if the term is already active or is non-active
        if( $thisLink.hasClass(activeTermClass) ) {
          return false;
        // if not, add active class
        } else if ( $thisLink.hasClass(nonActiveTermClass) ) {
          return false;
        } else {
          $thisLink.addClass(activeTermClass);
        }

        // Add Filtering class to container
        $classContainer.addClass(filteringClass);

        // Add term to array if it is not already there
        if($termFound < 0) {
          activeTermArray.push(termTarget);
        }

        // Update the location hash
        update_location_hash(activeTermArray);

        // Scurb Posts using the active term array
        scrub_classes(activeTermArray);

        // Add button to filter bar
        add_filter_bar_button($thisLink);

      });
      // ------------



      // REMOVE FILTER BAR LINKS
      // -----------------------
      // Clicking a link in the Filter Bar

      $(document).on('click', '.' + filterTagClass, function(event){
        event.preventDefault();

        // Add Filtering class to container
        $classContainer.addClass(filteringClass);

        var $thisLink      = $(this);
        var termLinkTarget = $thisLink.attr('data-link-id');
        var termTarget     = $thisLink.attr('data-tag-target');
        var $termFound     = $.inArray(termTarget, activeTermArray);

        var filterTagBar = $('#filter-term-bar');


        // Remove the term from the active array
        if($termFound > -1) {
          activeTermArray.splice($termFound, 1);
        }

        // Update the location hash
        update_location_hash(activeTermArray);

        // Scurb Posts using the active term array
        scrub_classes(activeTermArray);

        // Remove Active Class from associated term
        $('#' + termLinkTarget).removeClass(activeTermClass);

        // Remove the tag itself
        $thisLink.remove();

        // Remove the active class from the filter bar if empty
        if( filterTagBar.children().length === 0 ) {
          filterTagBar.parent().parent().removeClass('active-tags');
          $('#clear-all').fadeOut(fadeSpeed);
        }

      });
      // -----------------------



      // CLEAR ALL LINK
      // --------------

      $('#clear-all').click(function(event){
        event.preventDefault();

        var $filterTagBar = $('#filter-term-bar');
        var $classPost    = $('.class-post');

        // Empty out the filter bar
        $filterTagBar.children().fadeOut(fadeSpeed, function(){
          $filterTagBar.empty();
          // Remove active links from terms
          $('.filter-term-link').removeClass('active-term').removeClass(nonActiveTermClass);
        });

        // Remove the Clear all text
        $(this).fadeOut(fadeSpeed);

        // Show all classes
        $classPost.slideDown(fadeSpeed).removeClass('matching-class');

        // Hide no classes
        $noClasses.animate({'opacity': 0}, fadeSpeed, function(){
          $noClasses.attr('aria-hidden', 'true');
        });

        // Reset Array
        activeTermArray  = [];
        visibleTermArray = [];

      });
      // --------------

      // Toggle
      $('.tuck-toggle').click(function(){
        if( $('#filter-term-bar').children().length === 0 && $(this).hasClass('target-untucked') ) {
          $('#filter-bar-text-init').show().parent().parent().addClass('filter-init');
        }
      });


    }
  }
};

var UTIL = {
  fire: function(func, funcname, args) {
    var namespace = FleisherClasses;
    funcname = (funcname === undefined) ? 'init' : funcname;
    if (func !== '' && namespace[func] && typeof namespace[func][funcname] === 'function') {
      namespace[func][funcname](args);
    }
  },
  loadEvents: function() {
    // Fire common init JS
    UTIL.fire('common');

    // Fire page-specific init JS, and then finalize JS
    $.each(document.body.className.replace(/-/g, '_').split(/\s+/),function(i,classnm) {
      UTIL.fire(classnm);
      UTIL.fire(classnm, 'finalize');
    });

    // Fire common finalize JS
    UTIL.fire('common', 'finalize');
  }
};

// Load Events
$(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.

